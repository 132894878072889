import { Button, Stack, TextField, Alert, FormControlLabel, Checkbox } from '@mui/material';
import useLogin from '../hooks/accountModal/useLogin';

export default function LoginScreen({ swap, toForgot, handleClose }) {
    const { emailError, handleEmailChange, password, setPassword, onEnter, alertText, validEmail, loginButtonRef, loginUser, rememberMe, setRememberMe } = useLogin({ handleClose });

    return (
        <>
            <Stack alignItems="center" spacing={4} margin="1em" paddingBottom="1em">
                <h2>Login</h2>
                <br />
                <TextField
                    id="email"
                    label="Email Address"
                    type="email"
                    variant="outlined"
                    error={emailError}
                    helperText={emailError ? 'Invalid email' : ''}
                    onChange={(text) => handleEmailChange(text)}
                    sx={{ width: { xs: '100%', lg: '66%' } }}
                />
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    onChange={(text) => setPassword(text.target.value)}
                    onKeyDown={(event) => onEnter(event)}
                    sx={{ width: { xs: '100%', lg: '66%' } }}
                />
                {alertText.length > 0 ? <Alert severity="error">{alertText}</Alert> : null}
                <br />
                <FormControlLabel control={<Checkbox checked={rememberMe} />} label="Remember me" onChange={(event) => setRememberMe(event.target.checked)} />
                <Button variant="contained" disabled={!validEmail || password === ''} type="submit" ref={loginButtonRef} onClick={(event) => loginUser(event, rememberMe)}>
                    Login
                </Button>
                <br />
                <Stack direction="row" spacing={8}>
                    <p onClick={toForgot} style={{ cursor: 'pointer', borderBottom: '1px dotted' }}>
                        Forgot password?
                    </p>
                    <p onClick={swap} style={{ cursor: 'pointer', borderBottom: '1px dotted' }}>
                        Create an account
                    </p>
                </Stack>
            </Stack>
        </>
    );
}
