import { useState } from "react"
import { Stack, Box, IconButton, Divider, Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"

export default function FileTable({table}) {
    const [open, setOpen] = useState(true)
    const Head = table.head
    const Row = table.row

    return (
        <Box sx={{width: '86%'}}>
            <Stack 
                direction='row'
                sx={{justifyContent: 'space-between'}}  
                onClick={() => setOpen(!open)}
            >
                <h3>{table.title}</h3>
                <IconButton disableRipple disableFocusRipple >
                    { open ? <KeyboardArrowUp sx={{fontSize: 40}} />
                           : <KeyboardArrowDown sx={{fontSize: 40}} /> 
                    }
                </IconButton>
            </Stack>
            <Divider />
            { open ?  
                <Table>
                    <Head />
                    <TableBody>
                        {table.array.map((value, index) => {
                            return <Row value={value} index={index + 1} key={JSON.stringify(value)} />
                        })}
                    </TableBody>
                </Table>
            : null 
            }
        </Box>
    )
}

export function SupportedFormatsHead() {
    return (
        <TableHead>
            <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Format</TableCell>
                <TableCell>File Extension</TableCell>
                <TableCell>Used In</TableCell>
                <TableCell>Description</TableCell>
            </TableRow>
        </TableHead>
    )
}

export function SupportedFormatsRow({value, index}) {
    return (
        <TableRow key={value.name}>
            <TableCell>{index}</TableCell>
            <TableCell>{value.name}</TableCell>
            <TableCell>{value.extension}</TableCell>
            <TableCell>{value.useCase}</TableCell>
            <TableCell>
                <a href={value.descLink} target='_blank' rel='noreferrer'>
                    {value.description}
                </a>
            </TableCell>
        </TableRow>
    )
}

/* CURRENTLY UNUSED, MAYBE REMVOE IN THE FUTURE, DON'T WANT TO RIGHT NOW - 8/23 */
// export function SystemsHead () {
//     return (
//         <TableHead>
//             <TableRow>
//                 <TableCell>#</TableCell>
//                 <TableCell>System</TableCell>
//                 <TableCell>Description</TableCell>
//                 <TableCell>Source</TableCell>
//             </TableRow>
//         </TableHead>
//     )
// }

// export function SystemsRow({value, index}) {
//     return (
//         <TableRow>
//             <TableCell>{index}</TableCell>
//             <TableCell>{value.name}</TableCell>
//             <TableCell>
//                 <a href={value.descLink} target='_blank' rel='noreferrer'>
//                     {value.description}
//                 </a>
//             </TableCell>
//             <TableCell>{value.source}</TableCell>
//         </TableRow>
//     )
// }
