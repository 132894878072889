
export const DEV_URL = 'https://sustain-dev.cs.colostate.edu:31415'
export const PROD_URL = 'https://sustain.cs.colostate.edu:31415'

export default function getEnvoyUrl() {
	const BACKEND_MODE = process.env.REACT_APP_BACKEND_MODE
	if(BACKEND_MODE && BACKEND_MODE === 'prod') {
		return PROD_URL
	}
	return DEV_URL
}