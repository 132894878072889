import ReactGA from "react-ga4";
import { createContext, useContext, useState, useRef, useEffect } from "react";
import { useCookies } from 'react-cookie';
import Api from '../../Api';

export const SustainSessionContext = createContext(null);

export function SustainSessionProvider({children}) {

    const sustainSessionContextHook = useSustainSessionContext()

    return (
        <SustainSessionContext.Provider value={sustainSessionContextHook}>
            {children}
        </SustainSessionContext.Provider>
    )

}

const defaultUser = { 
    id: -1,
    name: "",
    email: "",
}

function useSustainSessionContext() {

    const [user, setUser] = useState(defaultUser);
    const lastTrackedPage = useRef('')

    const [cookies, setCookie] = useCookies(['sessionCookie', 'userCookie', 'rememberCookie'])

    /* eslint-disable-next-line */
    useEffect(() => void sendStartSession(setUser, cookies, setCookie), [])

    return {
        user,
        setUser,
        lastTrackedPage,
        startNewSession: () => sendStartSession(setUser, cookies, setCookie),
        updateCookiesAndUser: (objectWithCookies) => updateCookiesAndUser(objectWithCookies, setUser, setCookie)
    }

}

async function sendStartSession(setUser, cookies, setCookie) {
    const {sessionCookie, userCookie, rememberCookie} = cookies
    const responseBody = await Api.sendRequest({sessionCookie, userCookie, rememberCookie}, 'startSession')
    updateCookiesAndUser(responseBody, setUser, setCookie)
}

async function updateCookiesAndUser(objectWithCookies, setUser, setCookie) {
    const cookieOptions = {
        maxAge: 24 * 60 * 60 * 1000, // 1 day
        secure: true,
        sameSite: 'None'
    }
    const {sessionCookie, userCookie, rememberCookie} = objectWithCookies
    setCookie('sessionCookie', sessionCookie, cookieOptions)
    setCookie('userCookie', userCookie, cookieOptions)
    if(rememberCookie) setCookie('rememberCookie', rememberCookie, {...cookieOptions, maxAge: 365 * 24 * 60 * 60 * 1000}) // 1 year
    fetchUser(setUser, objectWithCookies)
}

async function fetchUser(setUser, cookies) {
    const {sessionCookie, userCookie, rememberCookie} = cookies
    const responseBody = await Api.sendRequest({sessionCookie, userCookie, rememberCookie}, 'getUserInfo')
    setUser(responseBody);
}


export default function useSustainSession() {

    const sustainSession = useContext(SustainSessionContext);

    const [cookies, setCookie, removeCookie] = useCookies(['sessionCookie', 'userCookie', 'rememberCookie'])

    const context = {
        sustainSession,
        cookies,
        setCookie, 
        removeCookie
    }

    return {
        user: sustainSession.user,
        getSessionId: () => getSessionId(context),
        logout: () => logout(context),
        trackPage: (page=undefined) => trackPage(context, page),
        updateCookiesAndUser: sustainSession.updateCookiesAndUser
    }
}

function getSessionId(context) {
    return context.cookies.sessionCookie
}

function logout(context) {
    context.removeCookie("rememberCookie")
    context.removeCookie("sessionCookie")
    context.removeCookie("userCookie")
    context.sustainSession.setUser(defaultUser)
    context.sustainSession.startNewSession()
}

function trackPage(context, url=undefined) {
    const sessionCookie = context.cookies.sessionCookie
    if(!sessionCookie) return // No session yet

    if(!url) {
        url = window.location.href
        if(context.sustainSession.lastTrackedPage.current === url) return // Don't track page again if just tracked
    }

    context.sustainSession.lastTrackedPage.current = url
    ReactGA.send({hitType: "pageview", page: window.location.pathname})
    void Api.sendRequest({url, sessionCookie}, 'pageView')
}
